import { FormErrors, FormMetadata } from '../lib/types';

export const getFormErrors = (formFields: FormMetadata, formValues: Record<string, any>): FormErrors | undefined => {
  const errors: FormErrors = {};
  Object.keys(formFields).forEach((fieldName) => {
    const { label, min, max, minValue, maxValue, nonZero, required } = formFields[fieldName];
    const formValue = formValues[fieldName];
    if (formValue !== undefined && formValue !== null && !Number.isNaN(formValue) && formValue !== '') {
      if (nonZero && formValue === 0) errors[fieldName] = `${label} cannot be zero`;
      if (min && formValue.length < min) errors[fieldName] = `${label} must be more than ${max} characters`;
      if (max && formValue.length > max) errors[fieldName] = `${label} must be less than ${max} characters`;
      if (minValue && (typeof formValue === 'string' ? parseFloat(formValue) : formValue) < minValue)
        errors[fieldName] = `${label} must be more than ${minValue}`;
      if (maxValue && (typeof formValue === 'string' ? parseFloat(formValue) : formValue) > maxValue)
        errors[fieldName] = `${label} must be less than ${maxValue}`;
    } else if (required) errors[fieldName] = `${label} is required`;

    if (fieldName.toLowerCase().includes('latitude') && (formValue.split('.')[1]?.length ?? 0) !== 6)
      errors[fieldName] = 'Latitude values must have 6 decimal places';
    if (fieldName.toLowerCase().includes('longitude') && (formValue.split('.')[1]?.length ?? 0) !== 6)
      errors[fieldName] = 'Longitude values must have 6 decimal places';
  });

  if (Object.keys(errors).length) return errors;
};
